import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { equals, find, includes, omit, path, pathOr, propEq } from 'ramda';
import { asyncConnect } from 'react-async-client';
import { pluralize } from 'numeralize-ru';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import InputNumber from './formComponents/InputNumber';
import Checkbox from './formComponents/Checkbox';
import Select from './formComponents/Select';
import { getConsultationDurations, getPromotionDurations, getPromotionServiceTypes, getUser } from '../../actions/asyncActions';
import { ADMIN, SUPER_ADMIN } from '../../constants/roles';
import Amount from './formComponents/Amount';
import { PROMOTION_TYPES } from '../../constants/modules';
import { COMPANY_BILLING_BY_PARTICIPANT } from '../../constants/dictionaries';

const Title = styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #2B3D4F;
`;

const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CostField = styled.div`
    display: ${({ hide }) => hide ? 'none' : 'block'};

    .cost-label-wrapper {
        position: relative;
        small {
            position: absolute;
            bottom: -12px;
            color: #ccc;
        }
    }
    .ant-input-number {
        width: 100%;
        position: relative;
        &:after {
            content: '₽';
            position: absolute;
            top: 4px;
            right: 8px;
        }
    }
`;

export const formatter = value => value ? parseInt(value).toLocaleString('ru-RU') : '';

export const parser = value => value ? parseInt(value.replace(/\s/g, '')) : null;

class CompanyRolesForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool,
        item: PropTypes.object,
        deleteCompanyRoles: PropTypes.object
    };

    deleteCompanyRoles = () => {
        const { deleteCompanyRoles, item } = this.props;

        deleteCompanyRoles.dispatch(item.id);
    }

    render() {
        const { isSubmitting, item, deleteCompanyRoles, user, values, company, getConsultationDurations, form, getPromotionDurations, getPromotionServiceTypes } = this.props;
        const disabled = isSubmitting || deleteCompanyRoles.meta.pending;
        const isAdmin = includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles);
        const modules = isAdmin ? pathOr([], ['modules'], company) : pathOr([], ['_embedded', 'company', 'modules'], user);

        return <div className='company-form'>
            <Field
                name='name'
                component={Input}
                label='Название' />
            { user.superAdmin &&
                <Field
                    name='expectedCountParticipants'
                    component={InputNumber}
                    label='Планируемое кол-во участников'
                    min={0} />
            }
            <Row gutter={16}>
                <Col span={6}>
                    <CostField hide={path(['billingType'], company) !== COMPANY_BILLING_BY_PARTICIPANT}>
                        <Field
                            name='costPerParticipant'
                            component={InputNumber}
                            label={<div className='cost-label-wrapper'>
                                <div className='cost-label'>Стоимость 1 участника</div>
                                <small>(без учета НДС)</small>
                            </div>}
                            formatter={formatter}
                            parser={parser}
                            min={0} />
                    </CostField>
                </Col>
            </Row>
            <Field
                name='modules'
                component={Select}
                label='Сервисы'
                options={modules}
                loading={!modules.length}
                namePath='title'
                onChange={value => {
                    const hasConsultations = includes('consultations', value || []);
                    const hasPromotion = includes('promotion', value || []);

                    form.batch(() => {
                        form.change('duration', hasConsultations ? (values.duration || pathOr(45, [0], getConsultationDurations.data)) : null);
                        form.change('amount', hasConsultations ? values.amount : null);
                        form.change('consultationCost', hasConsultations ? values.consultationCost : null);
                        form.change('promotionCost', hasPromotion ? values.promotionCost : null);
                        form.change('promotionDuration', hasPromotion ? (values.promotionDuration || pathOr(1, [0], getPromotionDurations.data)) : null);
                        form.change('serviceType', hasPromotion ? (values.serviceType || pathOr('promotion', [0], getPromotionServiceTypes.data)) : null);
                    });

                }}
                mode='multiple' />
            <Field
                name='fastRegistrationEnabled'
                component={Checkbox}
                text='Быстрая авторизация' />
            { find(equals('events'), values.modules || []) &&
                <Fragment>
                    <Title>Вебинары</Title>
                    <Field
                        name='events'
                        component={Select}
                        label='Темы вебинаров'
                        options={pathOr([], ['_embedded', 'themes'], find(propEq('id', 'events'), modules))}
                        loading={!modules.length}
                        namePath='title'
                        validate={value => value && value.length ? false : 'Это обязательное поле'}
                        destroyOnUnmount
                        mode='multiple' />
                </Fragment>
            }
            { find(equals('content'), values.modules || []) &&
                <Fragment>
                    <Title>Курсы</Title>
                    <Field
                        name='stages'
                        component={Select}
                        label='Модули'
                        options={pathOr([], ['_embedded', 'stages'], find(propEq('id', 'content'), modules))}
                        loading={!modules.length}
                        namePath='title'
                        validate={value => value && value.length ? false : 'Это обязательное поле'}
                        destroyOnUnmount
                        mode='multiple' />
                </Fragment>
            }
            <Field name='duration' component={() => null} />
            { find(equals('consultations'), values.modules || []) &&
                <Fragment>
                    <Title>Консультации</Title>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Field
                                name='duration'
                                component={Amount}
                                label='Время в минутах'
                                customOptions={getConsultationDurations.data} />
                        </Col>
                        <Col span={8}>
                            <Field
                                name='amount'
                                component={Amount}
                                label='Количество консультаций'
                                validate={value => value ? false : 'Укажите количество слотов'} />
                        </Col>
                        <Col span={8}>
                            <CostField hide={path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT}>
                                <Field
                                    name='consultationCost'
                                    component={InputNumber}
                                    label={<div className='cost-label-wrapper'>
                                        <div className='cost-label'>Стоимость 1 консультации</div>
                                        <small>(без учета НДС)</small>
                                    </div>}
                                    formatter={formatter}
                                    parser={parser}
                                    min={0} />
                            </CostField>
                        </Col>
                    </Row>
                </Fragment>
            }
            { find(equals('promotion'), values.modules || []) &&
                <Fragment>
                    <Title>Профессиональная поддержка</Title>
                    <Row gutter={16}>
                        <Col sm={12} xs={24}>
                            <Field
                                name='serviceType'
                                component={Select}
                                label='Вид поддержки'
                                validate={value => value ? false : 'Это обязательное поле'}
                                required
                                options={getPromotionServiceTypes.data.map(id => ({ id, name: PROMOTION_TYPES[id] }))}
                                loading={getPromotionServiceTypes.meta.pending}
                                destroyOnUnmount />
                        </Col>
                        <Col sm={5} xs={24}>
                            <Field
                                name='promotionDuration'
                                component={Select}
                                label='Период'
                                validate={value => value ? false : 'Это обязательное поле'}
                                destroyOnUnmount
                                required
                                loading={getPromotionDurations.meta.pending}
                                options={getPromotionDurations.data.map(amount => ({ name: `${amount} ${pluralize(amount, 'день', 'дня', 'дней')}`, id: amount }))} />
                        </Col>
                        <Col sm={7} xs={24}>
                            <CostField hide={path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT}>
                                <Field
                                    name='promotionCost'
                                    component={InputNumber}
                                    label={<div className='cost-label-wrapper'>
                                        <div className='cost-label'>Стоимость за 30 дней</div>
                                        <small>(без учета НДС)</small>
                                    </div>}
                                    formatter={formatter}
                                    parser={parser}
                                    min={0} />
                            </CostField>
                        </Col>
                    </Row>
                </Fragment>
            }
            { find(equals('chat'), values.modules || []) &&
                <Fragment>
                    <Title>Чат</Title>
                    <Row gutter={16}>
                        <Col sm={5} xs={24}>
                            <Field
                                name='chatDuration'
                                component={Select}
                                label='Период'
                                validate={value => value ? false : 'Это обязательное поле'}
                                destroyOnUnmount
                                required
                                loading={getPromotionDurations.meta.pending}
                                options={getPromotionDurations.data.map(amount => ({ name: `${amount} ${pluralize(amount, 'день', 'дня', 'дней')}`, id: amount }))} />
                        </Col>
                        <Col sm={7} xs={24}>
                            <CostField hide={path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT}>
                                <Field
                                    name='chatCost'
                                    component={InputNumber}
                                    label={<div className='cost-label-wrapper'>
                                        <div className='cost-label'>Стоимость за 30 дней</div>
                                        <small>(без учета НДС)</small>
                                    </div>}
                                    formatter={formatter}
                                    parser={parser}
                                    min={0} />
                            </CostField>
                        </Col>
                    </Row>
                </Fragment>
            }
            { find(equals('testing'), values.modules || []) &&
                <Fragment>
                    <Title>Тесты и личностные опросники</Title>
                    <Field
                        name='testing'
                        component={Select}
                        label='Темы тестирования'
                        options={pathOr([], ['_embedded', 'themes'], find(propEq('id', 'testing'), modules))}
                        loading={!modules.length}
                        namePath='title'
                        validate={value => value && value.length ? false : 'Это обязательное поле'}
                        destroyOnUnmount
                        mode='multiple' />
                </Fragment>
            }
            <FlexRow>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={disabled}>
                    Сохранить
                </Button>
                { item.id &&
                    <Button
                        danger
                        onClick={this.deleteCompanyRoles}
                        disabled={disabled}>
                        Удалить
                    </Button>
                }
            </FlexRow>
        </div>;
    }
}

const validationSchema = ({ user }) => yup.object().shape({
    name: yup.string().required(),
    company: (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) ? yup.string().nullable().required() : yup.string().nullable(),
    fastRegistrationEnabled: yup.boolean(),
});

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getConsultationDurations: getConsultationDurations
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true }),
    getPromotionDurations: getPromotionDurations
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getPromotionServiceTypes: getPromotionServiceTypes
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, stateToProps)(withFormWrapper(CompanyRolesForm, {
    mapPropsToValues: ({ item }) => item ? ({
        ...item,
        events: path(['themes'], find(propEq('id', 'events'), item.modules || [])),
        stages: path(['stages'], find(propEq('id', 'content'), item.modules || [])),
        testing: path(['themes'], find(propEq('id', 'testing'), item.modules || [])),
        amount: path(['amount'], find(propEq('id', 'consultations'), item.modules || [])),
        duration: path(['duration'], find(propEq('id', 'consultations'), item.modules || [])),
        consultationCost: path(['cost'], find(propEq('id', 'consultations'), item.modules || [])),
        promotionCost: path(['cost'], find(propEq('id', 'promotion'), item.modules || [])),
        promotionDuration: path(['duration'], find(propEq('id', 'promotion'), item.modules || [])),
        serviceType: path(['serviceType'], find(propEq('id', 'promotion'), item.modules || [])),
        chatCost: path(['cost'], find(propEq('id', 'chat'), item.modules || [])),
        chatDuration: path(['duration'], find(propEq('id', 'chat'), item.modules || [])),
        modules: item.modules ? item.modules.map(({ id }) => id) : []
    }) : null,
    validationSchema,
    mapBeforeSubmit: values => omit(['stages'], {
        ...values,
        modules: values.modules ? values.modules.map(id =>
            id === 'events' ? ({ id, themes: values.events }) :
            id === 'content' ? ({ id, stages: values.stages }) :
            id === 'testing' ? ({ id, themes: values.testing }) :
            id === 'consultations' ? ({ id, amount: values.amount, duration: values.duration, cost: values.consultationCost }) :
            id === 'promotion' ? ({ id, duration: values.promotionDuration, serviceType: values.serviceType, cost: values.promotionCost }) :
            id === 'chat' ? ({ id, duration: values.chatDuration, cost: values.chatCost }) : ({ id })
        ) : []
    }),
    subscriptions: { values: true }
}));
