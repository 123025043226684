import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip, message } from 'antd';
import { CloseOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';

import { getCompanyRoles, getUser } from '../actions/asyncActions';
import TableList from './TableList';
import { POST_COMPANY_ROLES, PUT_COMPANY_ROLES, DELETE_COMPANY_ROLES } from '../constants/actionTypes';
import { openCompanyRolesModal } from '../actions/modalActions';
import CompanyRolesFilter from './filters/CompanyRolesFilter';
import { append, includes, path, pathOr } from 'ramda';
import { ADMIN, CLIENT_MANAGER, SUPER_ADMIN } from '../constants/roles';
import pluralize from 'pluralize-ru';
import { PROMOTION_TYPES } from '../constants/modules';
import { COMPANY_BILLING_BY_PARTICIPANT } from '../constants/dictionaries';
import CopyToClipboard from 'react-copy-to-clipboard';

class CompanyRoles extends Component {
    onCopy = (_, result) => {
        result ? message.success('Ссылка успешно скопирована') : message.error('Не удалось скопировать ссылку');
    };

    getColumns = () => {
        const { user, company } = this.props;

        let columns = [
            {
                title: 'Роль',
                dataIndex: 'name',
                key: 'name',
                render: (name, data) => includes(CLIENT_MANAGER, user.roles) ?
                    name :
                    <span className='span-link' onClick={() => this.props.openCompanyRolesModal({ item: data, company: this.props.company })}>{name}</span>
            }
        ];

        columns = append({
            title: 'Сервисы',
            dataIndex: 'modules',
            key: 'modules',
            render: (modules, role) => (modules || []).map(item =>
                <div key={`module-${role.id}-${item.id}`}>
                    { item.title }
                    { item.themes ? ` (${pathOr([], ['_embedded', 'themes'], item).reduce((res, cur, index) => index > 0 ? `${res}, ${cur.title}` : cur.title, '')})` : '' }
                    { (item.stages && !!item.stages.length) ? ` (${pathOr([], ['_embedded', 'stages'], item).reduce((res, cur, index) => index > 0 ? `${res}, ${cur.title}` : cur.title, '')})` : '' }
                    { item.id === 'consultations' ? `(${item.amount} ${pluralize(item.amount, '', 'консультация', 'консультации', 'консультаций')}, ${item.duration} минут${item.cost && (path(['billingType'], company) !== COMPANY_BILLING_BY_PARTICIPANT) ? `, ${item.cost.toLocaleString('ru-RU')} ₽` : ''})` : '' }
                    { item.id === 'promotion' ? `(${PROMOTION_TYPES[item.serviceType]}, ${item.duration} ${pluralize(item.duration, '', 'день', 'дня', 'дней')}${item.cost && (path(['billingType'], company)!== COMPANY_BILLING_BY_PARTICIPANT) ? `, ${item.cost.toLocaleString('ru-RU')} ₽` : ''})` : '' }
                    { item.id === 'chat' ? `(${item.duration} ${pluralize(item.duration, '', 'день', 'дня', 'дней')}${item.cost && (path(['billingType'], company)!== COMPANY_BILLING_BY_PARTICIPANT) ? `, ${item.cost.toLocaleString('ru-RU')} ₽` : ''})` : '' }
                </div>
            )
        }, (includes(ADMIN, user.roles) || includes(SUPER_ADMIN, user.roles)) && !this.props.company ? [
            ...columns,
            {
                title: 'Компания',
                dataIndex: ['_embedded', 'company', 'name'],
                key: 'company'
            }
        ] : columns);

        columns = user.superAdmin ? append({
            title: 'Планируемое кол-во участников',
            dataIndex: 'expectedCountParticipants',
            key: 'expectedCountParticipants',
            render: (expectedCountParticipants, item) => expectedCountParticipants ? `${item.countParticipants}/${expectedCountParticipants}` : null
        }, columns) : columns;

        columns = append({
            title: 'Быстрая авторизация',
            dataIndex: 'registrationUrl',
            key: 'registrationUrl',
            align: 'center',
            render: registrationUrl => registrationUrl ?
                <CopyToClipboard
                    onCopy={this.onCopy}
                    text={registrationUrl}
                >
                    <Tooltip title={`URL быстрой авторизации: ${registrationUrl}`} placement='topRight'>
                        <Button icon={<LinkOutlined />} />
                    </Tooltip>
                </CopyToClipboard> :
                <CloseOutlined style={{ color: '#f54d2e' }} />
        }, columns);

        return columns;
    }

    renderToolbarButtons = () => {
        return !includes(CLIENT_MANAGER, this.props.user.roles) && <Button icon={<PlusOutlined />} type='primary' onClick={() => this.props.openCompanyRolesModal({ item: { company: path(['id'], this.props.company) }, company: this.props.company })}>
            Добавить
        </Button>
    }

    render() {
        return (
            <TableList
                staticFilter={{ company: path(['id'], this.props.company) }}
                action={getCompanyRoles}
                columns={this.getColumns()}
                buttons={this.renderToolbarButtons()}
                refreshActions={[POST_COMPANY_ROLES, PUT_COMPANY_ROLES, DELETE_COMPANY_ROLES]}
                filterForm={<CompanyRolesFilter hideCompany={!!this.props.company} />}
            />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps, { openCompanyRolesModal })(CompanyRoles);
