import React, { Component } from 'react';
import { Button, InputNumber, message, Switch } from 'antd';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Spin } from 'antd';
import { any, append, assocPath, dissocPath, filter, find, findIndex, path, pathOr, propEq, remove, without } from 'ramda';
import { DownOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import debounce from 'debounce';

import { getAdminModules, getEventThemes, getStages, getTestThemes, getUser, putCompany } from '../actions/asyncActions';
import { openWebinarModal, openTestModal } from '../actions/modalActions';
import { takeEvery } from 'redux-saga/effects';
import { POST_EVENT_THEMES, POST_TEST_THEMES } from '../constants/actionTypes';
import { CostField, formatter, parser } from './forms/CompanyRolesForm';
import { COMPANY_BILLING_BY_PARTICIPANT } from '../constants/dictionaries';

const moduleEnabled = (module, modules) => {
    return any(propEq('id', module), modules || []);
}

const Wrapper = styled.div`
    .ant-collapse-item > .ant-collapse-header {
        cursor: default;
        padding-left: 16px;
    }
`;

const ServicesTitle = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
    }
    h3 {
        font-weight: 500;
        font-size: 18px;
        margin: 0;
        color: #2B3D4F;
    }
`;

const ServicesHead = styled.div`
    width: 100%;
    padding-right: 30px;
    display: flex;
    p {
        margin: 8px 0 0;
    }
`;

const Arrow = styled.div`
    padding: 4px 10px 0 0;
`;

const BoxSwitch = styled.div`
    display: flex;
    align-items: center;
    small {
        margin-right: 10px;
    }
`;

const WrapSwitch = styled.div`
    display: flex;
    align-items: baseline;
`;

const CostInput = styled(CostField)`
    .ant-input-number {
        width: 110px;
    }
    .ant-input-number-handler-wrap {
        z-index: 1;
    }
`;

class ThemeSwitch extends Component {
    constructor() {
        super();

        this.state = {
            checked: false
        };
    }

    componentDidMount() {
        this.setState({ checked: this.isChecked(this.props) });
    }

    componentDidUpdate(prev) {
        if (this.isChecked(this.props) !== this.isChecked(prev)) {
            this.setState({ checked: this.isChecked(this.props) });
        }
    }

    isChecked = props => {
        return find(item => item === props.item.id, props.companyThemes || []);
    }

    onChange = checked => {
        this.setState({ checked });
        this.props.onChange(this.props.item.id, checked);
    }

    render() {
        const { item } = this.props;

        return <div style={{ marginBottom: 5 }}>
            <Switch checked={this.state.checked} onChange={this.onChange} style={{ marginRight: 10 }} /> { item.title }
        </div>;
    }
}

class PriceInput extends Component {
    constructor() {
        super();

        this.state = {
            value: null
        };
        this.onSave = debounce(this.onSave, 500);
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    componentDidUpdate(prev) {
        if (this.props.value !== prev.value) {
            this.setState({ value: this.props.value });
        }
    }

    onSave = value => this.props.onChange(value);

    onChange = value => {
        this.setState({ value });
        this.onSave(value);
    }

    render() {
        return <CostInput>
            <InputNumber
                value={this.state.value || ''}
                onChange={this.onChange}
                min={0}
                formatter={formatter}
                parser={parser}
                disabled={this.props.disabled} />
        </CostInput>;
    }
}

class CompanyModules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modules: props.company.modules || [],
            eventsChecked: moduleEnabled('events', props.company.modules || []),
            testsChecked: moduleEnabled('testing', props.company.modules || []),
            themesCollapsed: true,
            testThemesCollapsed: true,
            contentCollapsed: true
        };
    }

    onCheckContent = value => {
        const { getAdminModules: { data }, putCompany, company } = this.props;
        const modules = value ? append(find(propEq('id', 'content'), data), this.state.modules) : remove(findIndex(propEq('id', 'content'), this.state.modules), 1, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onCheckEvents = value => {
        const { getAdminModules: { data }, putCompany, company } = this.props;

        if (value) {
            this.setState({ modules: append({ ...find(propEq('id', 'events'), data), themes: [] }, this.state.modules), eventsChecked: true, themesCollapsed: false });
        } else {
            const modules = filter(item => item.id !== 'events', this.state.modules);

            this.setState({ modules, eventsChecked: false, themesCollapsed: true });
            putCompany.dispatch({
                ...company,
                modules
            });
        }
    }

    onCheckTests = value => {
        const { getAdminModules: { data }, putCompany, company } = this.props;

        if (value) {
            this.setState({ modules: append({ ...find(propEq('id', 'testing'), data), themes: [] }, this.state.modules), testsChecked: true, testThemesCollapsed: false });
        } else {
            const modules = filter(item => item.id !== 'testing', this.state.modules);

            this.setState({ modules, testsChecked: false, testThemesCollapsed: true });
            putCompany.dispatch({
                ...company,
                modules
            });
        }
    }

    onCheckConsultations = value => {
        const { getAdminModules: { data }, putCompany, company } = this.props;
        const modules = value ? append(find(propEq('id', 'consultations'), data), this.state.modules) : remove(findIndex(propEq('id', 'consultations'), this.state.modules), 1, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onCheckPromotion = value => {
        const { getAdminModules: { data }, putCompany, company } = this.props;
        const modules = value ? append(find(propEq('id', 'promotion'), data), this.state.modules) : remove(findIndex(propEq('id', 'promotion'), this.state.modules), 1, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onCheckChat = value => {
        const { getAdminModules: { data }, putCompany, company } = this.props;
        const modules = value ? append(find(propEq('id', 'chat'), data), this.state.modules) : remove(findIndex(propEq('id', 'chat'), this.state.modules), 1, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onCheckTheme = (theme, checked) => {
        const { putCompany, company } = this.props;
        const currentThemes = pathOr([], ['themes'], find(propEq('id', 'events'), this.state.modules));
        const themes = checked ? append(theme, currentThemes) : without([theme], currentThemes);
        const modules = assocPath([findIndex(propEq('id', 'events'), this.state.modules), 'themes'], themes, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onCheckStage = (stage, checked) => {
        const { putCompany, company } = this.props;
        const currentStages = pathOr([], ['stages'], find(propEq('id', 'content'), this.state.modules));
        const stages = checked ? append(stage, currentStages) : without([stage], currentStages);
        const modules = assocPath([findIndex(propEq('id', 'content'), this.state.modules), 'stages'], stages, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onCheckTestTheme = (theme, checked) => {
        const { putCompany, company } = this.props;
        const currentThemes = pathOr([], ['themes'], find(propEq('id', 'testing'), this.state.modules));
        const themes = checked ? append(theme, currentThemes) : without([theme], currentThemes);
        const modules = assocPath([findIndex(propEq('id', 'testing'), this.state.modules), 'themes'], themes, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onChangeContentCost = cost => {
        const { putCompany, company } = this.props;
        const index = findIndex(propEq('id', 'content'), this.state.modules);
        const modules = assocPath([index, 'cost'], cost, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onChangeThemeCost = (cost, theme) => {
        const { putCompany, company } = this.props;
        const currentThemesCost = pathOr([], ['themesCost'], find(propEq('id', 'events'), this.state.modules));
        const index = findIndex(propEq('theme', theme), currentThemesCost);
        const themesCost = index < 0 ?
            (cost ? append({ theme, cost }, currentThemesCost) : currentThemesCost) :
            (cost ? assocPath([index, 'cost'], cost, currentThemesCost) : dissocPath([index], currentThemesCost));
        const modules = assocPath([findIndex(propEq('id', 'events'), this.state.modules), 'themesCost'], themesCost, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onChangeStageCost = (cost, stage) => {
        const { putCompany, company } = this.props;
        const currentStagesCost = pathOr([], ['stagesCost'], find(propEq('id', 'content'), this.state.modules));
        const index = findIndex(propEq('stage', stage), currentStagesCost);
        const stagesCost = index < 0 ?
            (cost ? append({ stage, cost }, currentStagesCost) : currentStagesCost) :
            (cost ? assocPath([index, 'cost'], cost, currentStagesCost) : dissocPath([index], currentStagesCost));
        const modules = assocPath([findIndex(propEq('id', 'content'), this.state.modules), 'stagesCost'], stagesCost, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    onChangeTestThemeCost = (cost, theme) => {
        const { putCompany, company } = this.props;
        const currentThemesCost = pathOr([], ['themesCost'], find(propEq('id', 'testing'), this.state.modules));
        const index = findIndex(propEq('theme', theme), currentThemesCost);
        const themesCost = index < 0 ?
            (cost ? append({ theme, cost }, currentThemesCost) : currentThemesCost) :
            (cost ? assocPath([index, 'cost'], cost, currentThemesCost) : dissocPath([index], currentThemesCost));
        const modules = assocPath([findIndex(propEq('id', 'testing'), this.state.modules), 'themesCost'], themesCost, this.state.modules);

        this.setState({ modules });
        putCompany.dispatch({
            ...company,
            modules
        });
    }

    toggleEvents = () => {
        this.setState(prev => ({ themesCollapsed: !prev.themesCollapsed }));
    }

    toggleTests = () => {
        this.setState(prev => ({ testThemesCollapsed: !prev.testThemesCollapsed }));
    }

    toggleContent = () => {
        this.setState(prev => ({ contentCollapsed: !prev.contentCollapsed }));
    }

    render() {
        const { getEventThemes, getTestThemes, getAdminModules, user, company, getStages } = this.props;

        return (getAdminModules.meta.pending || (getTestThemes.meta.pending && !getTestThemes.meta.lastSucceedAt) || (getEventThemes.meta.pending && !getEventThemes.meta.lastSucceedAt)) ?
            <div style={{ textAlign: 'center' }}><Spin /></div> :
            <Wrapper style={{ margin: 16, marginTop: 0 }} className='ant-collapse'>
                <div className='ant-collapse-item'>
                    <div className='ant-collapse-header' style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 42 }}>
                        <ServicesHead>
                            <div>
                                <ServicesTitle>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.222 0.657528C1.16848 0.846404 0.444083 1.67125 0.32801 2.73893C0.178123 4.11764 0.0192349 6.4766 0.0217969 10.2728C0.0243589 14.0691 0.186431 16.4277 0.338178 17.8061C0.455693 18.8735 1.1812 19.6969 2.23498 19.8836C3.17148 20.0496 4.5786 20.2308 6.62453 20.3517L6.62618 22.8066C6.62687 23.8158 7.79044 24.3757 8.57525 23.7444L12.6228 20.4889C17.4553 20.4595 20.2672 20.1397 21.8068 19.8637C22.8603 19.6748 23.5847 18.85 23.7008 17.7823C23.8506 16.4036 24.0095 14.0446 24.007 10.2484C24.0044 6.45218 23.8423 4.09355 23.6906 2.71515C23.5731 1.6477 22.8476 0.824335 21.7938 0.637604C20.1911 0.353597 17.21 0.0249767 12.0075 0.0302726C6.80498 0.0355685 3.82434 0.370258 2.222 0.657528Z" fill="#BA9B66" fillOpacity="0.2"/>
                                        <path d="M9.6941 9.91765C9.51747 10.0853 9.43263 10.3122 9.43957 10.5982L9.43882 10.5982L9.43971 11.9275C9.44007 12.4498 9.8511 12.8729 10.3578 12.8723L11.2156 12.8715C11.7223 12.8709 12.1327 12.4471 12.1324 11.9248L12.1322 11.6323L12.2607 11.5703C12.8359 11.2894 13.346 10.9978 13.7909 10.6956C14.2359 10.3933 14.5966 10.0647 14.8731 9.70955C15.161 9.35313 15.3634 8.95841 15.4804 8.52538C15.6075 8.07921 15.6409 7.57167 15.5805 7.00279C15.4572 5.8413 15.0026 4.95718 14.2168 4.35042C13.4424 3.74236 12.3769 3.51507 11.0202 3.66856C10.5488 3.72189 10.1112 3.81938 9.70739 3.96102C9.30229 4.09081 8.95397 4.26216 8.66242 4.47505C8.37087 4.68795 8.14695 4.93518 7.99068 5.21673C7.84591 5.49698 7.79177 5.80896 7.82826 6.15266C7.85635 6.41722 7.9431 6.65095 8.07887 6.84821C8.36441 7.26303 8.93408 7.12635 9.34523 6.84492C9.44826 6.7744 9.5535 6.70842 9.66098 6.64698C9.99978 6.44073 10.4049 6.31094 10.8763 6.25761C11.3936 6.19908 11.7754 6.28782 12.0215 6.52385C12.2792 6.75858 12.4243 7.03001 12.4571 7.33816C12.4936 7.68186 12.4209 7.98394 12.2391 8.24439C12.0561 8.49299 11.799 8.70198 11.468 8.87138L10.4747 9.37956C10.1551 9.54765 9.89493 9.72701 9.6941 9.91765Z" fill="#BA9B66"/>
                                        <path d="M9.0922 15.737C9.09186 15.2362 9.23616 14.8307 9.52511 14.5204C9.82561 14.2101 10.2361 14.0547 10.7566 14.0542C11.277 14.0536 11.682 14.2082 11.9713 14.5179C12.2722 14.8276 12.4229 15.2328 12.4232 15.7336C12.4236 16.2344 12.2735 16.6399 11.973 16.9502C11.684 17.2605 11.2793 17.4159 10.7588 17.4164C10.2384 17.417 9.82767 17.2624 9.52675 16.9527C9.23739 16.643 9.09254 16.2378 9.0922 15.737Z" fill="#BA9B66"/>
                                    </svg>
                                    <h3>Консультации</h3>
                                </ServicesTitle>
                                <p>На консультациях можно получить ценные рекомендации о том, как планировать карьеру, составлять резюме и сопроводительные письма, определять приоритетные инструменты поиска работы, готовиться к собеседованиям и успешно проходить все этапы отбора.</p>
                            </div>
                        </ServicesHead>
                        <WrapSwitch>
                            <BoxSwitch>
                                <small>{ moduleEnabled('consultations', this.state.modules) ? 'подключен' : 'отключен' }</small> <Switch onChange={this.onCheckConsultations} checked={moduleEnabled('consultations', this.state.modules)} />
                            </BoxSwitch>
                        </WrapSwitch>
                    </div>
                </div>
                <div className='ant-collapse-item'>
                    <div className='ant-collapse-header' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ServicesHead className={ this.state.eventsChecked ? "collapse-hover" : null}>
                            <Arrow>{ this.state.eventsChecked ? <DownOutlined onClick={this.toggleEvents} /> : <div style={{ width: 14 }} /> }</Arrow>
                            <div>
                                <ServicesTitle>
                                    <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5691 16.47C14.4621 16.4633 17.01 17.949 18.6253 19.5516C20.3683 21.2808 18.9242 23.7243 16.4729 23.8732C14.844 23.9722 12.8469 24.0505 10.5789 24.0544C8.31084 24.0583 6.31363 23.9868 4.68443 23.8935C2.23275 23.753 0.782344 21.3145 2.52086 19.5793C4.13202 17.9712 6.67607 16.4767 10.5691 16.47Z" fill="#75AD56"/>
                                        <ellipse rx="10.5391" ry="10.5343" transform="matrix(0.999996 -0.00172589 0.00129048 0.999997 10.5739 10.5887)" fill="#E2FFD1"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5826 17.6126C14.4631 17.6059 17.6048 14.4561 17.5998 10.5773C17.5948 6.6985 14.445 3.55955 10.5645 3.56624C6.68406 3.57294 3.54239 6.72275 3.54739 10.6015C3.5524 14.4803 6.70219 17.6193 10.5826 17.6126ZM10.5811 15.8572C13.4915 15.8521 15.8479 13.4897 15.8441 10.5804C15.8403 7.6712 13.4779 5.31687 10.5675 5.32189C7.6571 5.32691 5.30078 7.68938 5.30454 10.5986C5.3083 13.5079 7.6707 15.8622 10.5811 15.8572Z" fill="#75AD56"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.0629 10.175C14.0167 9.78044 13.4832 9.70009 13.1041 9.81878C12.972 9.86012 12.8316 9.8825 12.686 9.88276C11.9099 9.8841 11.28 9.25635 11.279 8.48064C11.2788 8.33418 11.3011 8.19291 11.3425 8.06009C11.4609 7.68068 11.3802 7.14693 10.9855 7.10108C10.849 7.08522 10.7102 7.07719 10.5695 7.07743C8.62938 7.08078 7.05867 8.65553 7.06117 10.5947C7.06367 12.5339 8.63844 14.1033 10.5785 14.0999C12.5186 14.0966 14.0893 12.5218 14.0868 10.5826C14.0866 10.4447 14.0785 10.3087 14.0629 10.175Z" fill="#75AD56"/>
                                    </svg>
                                    <h3 onClick={this.toggleEvents}>Вебинары и семинары</h3>
                                </ServicesTitle>
                                <p>Мероприятия помогут разобраться в том, что из себя представляет современный рынок труда, с чего начать поиск работы, как эффективно взаимодействовать с компаниями-работодателями и управлять карьерой</p>
                            </div>
                        </ServicesHead>
                        <WrapSwitch>
                            <BoxSwitch>
                                <small>{ this.state.eventsChecked ? 'подключен' : 'отключен' }</small> <Switch onChange={this.onCheckEvents} checked={this.state.eventsChecked} />
                            </BoxSwitch>
                        </WrapSwitch>
                    </div>
                    { this.state.eventsChecked && !this.state.themesCollapsed &&
                        <div className='ant-collapse-content'>
                            <div style={{ padding: '15px 15px 10px 42px' }}>
                                { (getEventThemes.meta.pending && !getEventThemes.meta.lastSucceedAt) ?
                                    <div style={{ textAlign: 'center', padding: 15 }}><Spin /></div> :
                                    (getEventThemes.data.items || []).map(item =>
                                        <div key={item.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0' }}>
                                            <ThemeSwitch
                                                item={item}
                                                companyThemes={pathOr([], ['themes'], find(propEq('id', 'events'), this.state.modules || []))}
                                                onChange={this.onCheckTheme} />
                                            <div style={{ display: path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT ? 'none' : 'flex', alignItems: 'center' }}>
                                                <div style={{ lineHeight: 1, marginRight: 15 }}>
                                                    <div>Стоимость</div>
                                                    <small style={{ color: '#ccc' }}>(без учета НДС)</small>
                                                </div>
                                                <PriceInput
                                                    value={path(['cost'], find(propEq('theme', item.id), pathOr([], ['themesCost'], find(propEq('id', 'events'), this.state.modules))))}
                                                    onChange={value => this.onChangeThemeCost(value, item.id)}
                                                    disabled={!find(i => i === item.id, pathOr([], ['themes'], find(propEq('id', 'events'), this.state.modules || [])))} />
                                            </div>
                                        </div>
                                    )
                                }
                                { user.superAdmin &&
                                    <Button
                                        style={{ marginTop: 10 }}
                                        type='primary'
                                        icon={getEventThemes.meta.pending ? <LoadingOutlined /> : <PlusOutlined />}
                                        onClick={() => this.props.openWebinarModal()}>
                                        Добавить тему
                                    </Button>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className='ant-collapse-item'>
                    <div className='ant-collapse-header' style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 42 }}>
                        <ServicesHead>
                            <div>
                                <ServicesTitle>
                                    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 0.00569141C16.7826 0.00338651 20.8095 3.24026 22.0054 7.64311C23.1238 7.73644 24.0026 8.6733 24.0029 9.81571L24.0035 11.997C24.0039 13.115 23.1628 14.0369 22.0787 14.1643C21.8062 18.5855 17.8613 22.3611 13.3702 22.3632C12.9183 22.3635 12.5519 21.9974 12.5518 21.5457C12.5517 21.0939 12.9179 20.7275 13.3697 20.7273C16.9517 20.7256 20.1604 17.6898 20.4372 14.18L20.186 14.1801L19.0951 14.1807L19.0941 10.9087C19.093 6.99347 15.9173 3.82106 12.0011 3.82295C8.08492 3.82483 4.91115 7.0003 4.9123 10.9156L4.91327 14.1875L3.82236 14.188L2.36655 14.1887L2.18599 14.1888C0.981008 14.1894 0.00388719 13.2133 0.00353247 12.0086L0.0028902 9.82728C0.00255383 8.68487 0.880718 7.74716 1.99911 7.65275C3.1924 3.24875 7.2174 0.0079963 12 0.00569141Z" fill="#8C4484"/>
                                        <path d="M20.7306 10.9083C20.732 15.7274 16.8258 19.636 12.0059 19.6383C7.18592 19.6406 3.27743 15.7358 3.27601 10.9167C3.27459 6.09759 7.18078 2.18904 12.0007 2.18671C16.8207 2.18439 20.7292 6.08918 20.7306 10.9083Z" fill="#F3E7FF"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.82156 12.8228C10.2735 12.8226 10.6399 13.1886 10.64 13.6404C10.6402 14.3933 11.251 15.0034 12.0041 15.0031C12.7573 15.0027 13.3677 14.392 13.3674 13.6391C13.3673 13.1873 13.7335 12.8209 14.1854 12.8207C14.6373 12.8205 15.0038 13.1865 15.0039 13.6383C15.0044 15.2948 13.6616 16.6383 12.0046 16.6391C10.3477 16.6399 9.00406 15.2977 9.00357 13.6412C9.00344 13.1894 9.36967 12.823 9.82156 12.8228Z" fill="#8C4484"/>
                                        <path d="M9.27503 9.27835C9.27521 9.88071 8.78691 10.3693 8.18438 10.3695C7.58186 10.3698 7.09327 9.88176 7.09309 9.2794C7.09292 8.67703 7.58122 8.18849 8.18374 8.1882C8.78627 8.1879 9.27485 8.67598 9.27503 9.27835Z" fill="#8C4484"/>
                                        <path d="M16.9118 9.27467C16.912 9.87703 16.4237 10.3656 15.8212 10.3659C15.2186 10.3662 14.7301 9.87808 14.7299 9.27572C14.7297 8.67335 15.218 8.18481 15.8205 8.18452C16.423 8.18423 16.9116 8.6723 16.9118 9.27467Z" fill="#8C4484"/>
                                    </svg>
                                    <h3>Профессиональная поддержка</h3>
                                </ServicesTitle>
                                <p>Инструменты помогут оценить навыки, компетенции и личностные качества, выявить потенциальные зоны роста и составить план дальнейшего профессионального развития.</p>
                            </div>
                        </ServicesHead>
                        <WrapSwitch>
                            <BoxSwitch>
                                <small>{ moduleEnabled('promotion', this.state.modules) ? 'подключен' : 'отключен' }</small> <Switch onChange={this.onCheckPromotion} checked={moduleEnabled('promotion', this.state.modules)} />
                            </BoxSwitch>
                        </WrapSwitch>
                    </div>
                </div>
                <div className='ant-collapse-item'>
                    <div className='ant-collapse-header' style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 42 }}>
                        <ServicesHead>
                            <div>
                                <ServicesTitle>
                                    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 0.00569141C16.7826 0.00338651 20.8095 3.24026 22.0054 7.64311C23.1238 7.73644 24.0026 8.6733 24.0029 9.81571L24.0035 11.997C24.0039 13.115 23.1628 14.0369 22.0787 14.1643C21.8062 18.5855 17.8613 22.3611 13.3702 22.3632C12.9183 22.3635 12.5519 21.9974 12.5518 21.5457C12.5517 21.0939 12.9179 20.7275 13.3697 20.7273C16.9517 20.7256 20.1604 17.6898 20.4372 14.18L20.186 14.1801L19.0951 14.1807L19.0941 10.9087C19.093 6.99347 15.9173 3.82106 12.0011 3.82295C8.08492 3.82483 4.91115 7.0003 4.9123 10.9156L4.91327 14.1875L3.82236 14.188L2.36655 14.1887L2.18599 14.1888C0.981008 14.1894 0.00388719 13.2133 0.00353247 12.0086L0.0028902 9.82728C0.00255383 8.68487 0.880718 7.74716 1.99911 7.65275C3.1924 3.24875 7.2174 0.0079963 12 0.00569141Z" fill="#8C4484"/>
                                        <path d="M20.7306 10.9083C20.732 15.7274 16.8258 19.636 12.0059 19.6383C7.18592 19.6406 3.27743 15.7358 3.27601 10.9167C3.27459 6.09759 7.18078 2.18904 12.0007 2.18671C16.8207 2.18439 20.7292 6.08918 20.7306 10.9083Z" fill="#F3E7FF"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.82156 12.8228C10.2735 12.8226 10.6399 13.1886 10.64 13.6404C10.6402 14.3933 11.251 15.0034 12.0041 15.0031C12.7573 15.0027 13.3677 14.392 13.3674 13.6391C13.3673 13.1873 13.7335 12.8209 14.1854 12.8207C14.6373 12.8205 15.0038 13.1865 15.0039 13.6383C15.0044 15.2948 13.6616 16.6383 12.0046 16.6391C10.3477 16.6399 9.00406 15.2977 9.00357 13.6412C9.00344 13.1894 9.36967 12.823 9.82156 12.8228Z" fill="#8C4484"/>
                                        <path d="M9.27503 9.27835C9.27521 9.88071 8.78691 10.3693 8.18438 10.3695C7.58186 10.3698 7.09327 9.88176 7.09309 9.2794C7.09292 8.67703 7.58122 8.18849 8.18374 8.1882C8.78627 8.1879 9.27485 8.67598 9.27503 9.27835Z" fill="#8C4484"/>
                                        <path d="M16.9118 9.27467C16.912 9.87703 16.4237 10.3656 15.8212 10.3659C15.2186 10.3662 14.7301 9.87808 14.7299 9.27572C14.7297 8.67335 15.218 8.18481 15.8205 8.18452C16.423 8.18423 16.9116 8.6723 16.9118 9.27467Z" fill="#8C4484"/>
                                    </svg>
                                    <h3>Чат</h3>
                                </ServicesTitle>
                            </div>
                        </ServicesHead>
                        <WrapSwitch>
                            <BoxSwitch>
                                <small>{ moduleEnabled('chat', this.state.modules) ? 'подключен' : 'отключен' }</small> <Switch onChange={this.onCheckChat} checked={moduleEnabled('chat', this.state.modules)} />
                            </BoxSwitch>
                        </WrapSwitch>
                    </div>
                </div>
                <div className='ant-collapse-item'>
                    <div className='ant-collapse-header' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ServicesHead className={moduleEnabled('content', this.state.modules) ? "collapse-hover" : null}>
                            <Arrow>{ moduleEnabled('content', this.state.modules) ? <DownOutlined onClick={this.toggleContent} /> : <div style={{ width: 14 }} /> }</Arrow>
                            <div>
                                <ServicesTitle>
                                    <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.63198 16.8761C3.90668 16.1017 8.18427 15.0937 11.7651 16.8161C11.927 16.894 12.1166 16.8937 12.2783 16.8152C15.8547 15.0805 20.1349 16.0737 22.4115 16.8403C23.1726 17.0965 24.0213 16.5401 24.0203 15.7384L24.0049 3.8366C24.0041 3.18845 23.6219 2.59313 23.0033 2.39614C19.7629 1.36428 15.2251 1.46624 12.2234 2.69715C12.0822 2.75504 11.9248 2.75531 11.7835 2.69791C8.77867 1.47734 4.24056 1.39101 1.0029 2.43404C0.384818 2.63316 0.00414651 3.22979 0.00498465 3.87794L0.0203752 15.7797C0.021412 16.5814 0.871601 17.1349 1.63198 16.8761Z" fill="#47AAC4"/>
                                        <path d="M1.91305 14.9905C4.76414 13.9426 8.77809 13.9767 11.5405 15.0971C11.8488 15.2221 12.1945 15.2215 12.5024 15.0954C15.2619 13.9655 19.2758 13.9176 22.1295 14.9556C22.5074 15.0931 22.9294 14.8246 22.9289 14.4231L22.9126 1.81599C22.912 1.39804 22.674 1.01249 22.2836 0.861903C19.4209 -0.242258 15.2996 -0.215701 12.484 0.937156C12.1761 1.06324 11.8305 1.06383 11.5223 0.938813C8.70376 -0.204336 4.58234 -0.216692 1.72254 0.897328C1.3325 1.04926 1.0955 1.43563 1.09604 1.85358L1.11234 14.4607C1.11286 14.8622 1.53559 15.1292 1.91305 14.9905Z" fill="#DBEEFB"/>
                                        <path d="M11.4661 0.897217C11.4873 0.905805 11.5085 0.914458 11.5296 0.923178C11.833 1.04849 12.1732 1.0479 12.4763 0.921527C12.4974 0.912735 12.5185 0.904007 12.5398 0.895345L12.5579 15.0871C12.5367 15.0957 12.5155 15.1045 12.4945 15.1133C12.1914 15.2396 11.8511 15.2402 11.5477 15.1149C11.5266 15.1062 11.5054 15.0975 11.4842 15.089L11.4661 0.897217Z" fill="#47AAC4"/>
                                        <path d="M3.99623 6.60003C3.63804 6.68466 3.28017 6.42263 3.2797 6.05475L3.27899 5.50815C3.27853 5.15087 3.50952 4.83141 3.85624 4.74448C5.58923 4.31002 7.50816 4.30671 9.24227 4.7352C9.58922 4.82092 9.82103 5.13959 9.82149 5.49687L9.8222 6.04347C9.82267 6.41135 9.46548 6.67462 9.10707 6.59122C7.45548 6.20693 5.64683 6.21005 3.99623 6.60003Z" fill="#47AAC4"/>
                                        <path d="M4.00187 10.96C3.64367 11.0446 3.2858 10.7826 3.28533 10.4147L3.28462 9.8681C3.28416 9.51082 3.51515 9.19135 3.86187 9.10443C5.59487 8.66997 7.5138 8.66666 9.2479 9.09514C9.59485 9.18087 9.82666 9.49954 9.82712 9.85682L9.82783 10.4034C9.82831 10.7713 9.47112 11.0346 9.11271 10.9512C7.46111 10.5669 5.65246 10.57 4.00187 10.96Z" fill="#47AAC4"/>
                                        <path d="M20.0253 6.59122C20.3837 6.67462 20.7409 6.41135 20.7404 6.04347L20.7397 5.49687C20.7392 5.13959 20.5074 4.82092 20.1605 4.7352C18.4264 4.30671 16.5074 4.31002 14.7745 4.74448C14.4277 4.83141 14.1967 5.15087 14.1972 5.50816L14.1979 6.05475C14.1984 6.42264 14.5563 6.68466 14.9144 6.60003C16.565 6.21005 18.3737 6.20693 20.0253 6.59122Z" fill="#47AAC4"/>
                                        <path d="M20.0309 10.9512C20.3893 11.0346 20.7465 10.7713 20.746 10.4034L20.7453 9.85682C20.7449 9.49953 20.5131 9.18087 20.1661 9.09514C18.432 8.66666 16.5131 8.66997 14.7801 9.10443C14.4334 9.19135 14.2024 9.51082 14.2028 9.8681L14.2035 10.4147C14.204 10.7826 14.5619 11.0446 14.9201 10.96C16.5707 10.57 18.3793 10.5669 20.0309 10.9512Z" fill="#47AAC4"/>
                                    </svg>
                                    <h3>Курсы</h3>
                                </ServicesTitle>
                            </div>
                        </ServicesHead>
                        <WrapSwitch>
                            <BoxSwitch>
                                <small>{ moduleEnabled('content', this.state.modules) ? 'подключен' : 'отключен' }</small> <Switch onChange={this.onCheckContent} checked={moduleEnabled('content', this.state.modules)} />
                            </BoxSwitch>
                        </WrapSwitch>
                    </div>
                    { moduleEnabled('content', this.state.modules) && !this.state.contentCollapsed &&
                        <div className='ant-collapse-content'>
                            <div style={{ padding: '15px 15px 10px 42px' }}>
                                { (getStages.meta.pending && !getStages.meta.lastSucceedAt) ?
                                    <div style={{ textAlign: 'center', padding: 15 }}><Spin /></div> :
                                    (getStages.data.items || []).map(item =>
                                        <div key={item.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0' }}>
                                            <ThemeSwitch
                                                item={item}
                                                companyThemes={pathOr([], ['stages'], find(propEq('id', 'content'), this.state.modules || []))}
                                                onChange={this.onCheckStage} />
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ lineHeight: 1, marginRight: 15 }}>
                                                    <div>Стоимость</div>
                                                    <small style={{ color: '#ccc' }}>(без учета НДС)</small>
                                                </div>
                                                <PriceInput
                                                    value={path(['cost'], find(propEq('stage', item.id), pathOr([], ['stagesCost'], find(propEq('id', 'content'), this.state.modules))))}
                                                    onChange={value => this.onChangeStageCost(value, item.id)}
                                                    disabled={!find(i => i === item.id, pathOr([], ['stages'], find(propEq('id', 'content'), this.state.modules || [])))} />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                </div>

                <div className='ant-collapse-item'>
                    <div className='ant-collapse-header' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ServicesHead className={ this.state.testsChecked ? "collapse-hover" : null}>
                            <Arrow>{ this.state.testsChecked ? <DownOutlined onClick={this.toggleTests} /> : <div style={{ width: 14 }} /> }</Arrow>
                            <div>
                                <ServicesTitle>
                                    <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5691 16.47C14.4621 16.4633 17.01 17.949 18.6253 19.5516C20.3683 21.2808 18.9242 23.7243 16.4729 23.8732C14.844 23.9722 12.8469 24.0505 10.5789 24.0544C8.31084 24.0583 6.31363 23.9868 4.68443 23.8935C2.23275 23.753 0.782344 21.3145 2.52086 19.5793C4.13202 17.9712 6.67607 16.4767 10.5691 16.47Z" fill="#75AD56"/>
                                        <ellipse rx="10.5391" ry="10.5343" transform="matrix(0.999996 -0.00172589 0.00129048 0.999997 10.5739 10.5887)" fill="#E2FFD1"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5826 17.6126C14.4631 17.6059 17.6048 14.4561 17.5998 10.5773C17.5948 6.6985 14.445 3.55955 10.5645 3.56624C6.68406 3.57294 3.54239 6.72275 3.54739 10.6015C3.5524 14.4803 6.70219 17.6193 10.5826 17.6126ZM10.5811 15.8572C13.4915 15.8521 15.8479 13.4897 15.8441 10.5804C15.8403 7.6712 13.4779 5.31687 10.5675 5.32189C7.6571 5.32691 5.30078 7.68938 5.30454 10.5986C5.3083 13.5079 7.6707 15.8622 10.5811 15.8572Z" fill="#75AD56"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.0629 10.175C14.0167 9.78044 13.4832 9.70009 13.1041 9.81878C12.972 9.86012 12.8316 9.8825 12.686 9.88276C11.9099 9.8841 11.28 9.25635 11.279 8.48064C11.2788 8.33418 11.3011 8.19291 11.3425 8.06009C11.4609 7.68068 11.3802 7.14693 10.9855 7.10108C10.849 7.08522 10.7102 7.07719 10.5695 7.07743C8.62938 7.08078 7.05867 8.65553 7.06117 10.5947C7.06367 12.5339 8.63844 14.1033 10.5785 14.0999C12.5186 14.0966 14.0893 12.5218 14.0868 10.5826C14.0866 10.4447 14.0785 10.3087 14.0629 10.175Z" fill="#75AD56"/>
                                    </svg>
                                    <h3 onClick={this.toggleTests}>Тесты и личностные опросники</h3>
                                </ServicesTitle>
                            </div>
                        </ServicesHead>
                        <WrapSwitch>
                            <BoxSwitch>
                                <small>{ this.state.testsChecked ? 'подключен' : 'отключен' }</small> <Switch onChange={this.onCheckTests} checked={this.state.testsChecked} />
                            </BoxSwitch>
                        </WrapSwitch>
                    </div>
                    { this.state.testsChecked && !this.state.testThemesCollapsed &&
                        <div className='ant-collapse-content'>
                            <div style={{ padding: '15px 15px 10px 42px' }}>
                                { (getTestThemes.meta.pending && !getTestThemes.meta.lastSucceedAt) ?
                                    <div style={{ textAlign: 'center', padding: 15 }}><Spin /></div> :
                                    (getTestThemes.data.items || []).map(item =>
                                        <div key={item.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0' }}>
                                            <ThemeSwitch
                                                item={item}
                                                companyThemes={pathOr([], ['themes'], find(propEq('id', 'testing'), this.state.modules || []))}
                                                onChange={this.onCheckTestTheme} />
                                            <div style={{ display: path(['billingType'], company) === COMPANY_BILLING_BY_PARTICIPANT ? 'none' : 'flex', alignItems: 'center' }}>
                                                <div style={{ lineHeight: 1, marginRight: 15 }}>
                                                    <div>Стоимость</div>
                                                    <small style={{ color: '#ccc' }}>(без учета НДС)</small>
                                                </div>
                                                <PriceInput
                                                    value={path(['cost'], find(propEq('theme', item.id), pathOr([], ['themesCost'], find(propEq('id', 'testing'), this.state.modules))))}
                                                    onChange={value => this.onChangeTestThemeCost(value, item.id)}
                                                    disabled={!find(i => i === item.id, pathOr([], ['themes'], find(propEq('id', 'testing'), this.state.modules || [])))} />
                                            </div>
                                        </div>
                                    )
                                }
                                { user.superAdmin &&
                                    <Button
                                        style={{ marginTop: 10 }}
                                        type='primary'
                                        icon={getTestThemes.meta.pending ? <LoadingOutlined /> : <PlusOutlined />}
                                        onClick={() => this.props.openTestModal()}>
                                        Добавить тестирование
                                    </Button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Wrapper>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getAdminModules: getAdminModules
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getEventThemes: getEventThemes
        .withPayload(() => ({
            limit: 0
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_EVENT_THEMES)], () => {
                getProps().getEventThemes.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getTestThemes: getTestThemes
        .withPayload(() => ({
            limit: 0
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_TEST_THEMES)], () => {
                getProps().getTestThemes.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getStages: getStages
        .withPayload(() => ({
            limit: 0
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    putCompany: putCompany
        .withErrorHandler(() => message.error('Не удалось сохранить изменения'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openWebinarModal, openTestModal })(CompanyModules);
