import React, { Component } from 'react';
import { Modal, Row, Select, Col, Spin } from 'antd';
import { asyncConnect } from 'react-async-client';
import { withState } from 'recompose';
import qs from 'qs';

import { getParticipantLimit, getParticipants, getUser } from '../../actions/asyncActions';
import { any, filter, find, includes, pathOr, prop, propEq } from 'ramda';

const modules = [
    { id: 'consultations', name: 'Консультации' },
    { id: 'promotion', name: 'Профессиональная поддержка' }
];

const modulesLimitTypes = {
    'consultations': 'limit',
    'promotion': 'promotion-available',
};

class AddMeetingModal extends Component {
    onChangeConsultant = participant => {
        this.props.setParticipant(participant);
        this.props.setModule(null);
    }

    onChangeModule = module => {
        this.props.setModule(module);
    }

    close = () => {
        this.props.close();
        this.props.params.onClose && this.props.params.onClose();
    }

    getModules = participant => {
        return filter(({ id }) => any(propEq('id', id), participant.modules || []), modules);
    }

    getDuration = participant => {
        return prop('duration', find(propEq('id', 'consultations'), participant.modules || []));
    }

    render() {
        const { modal, getParticipants, getParticipantLimit, user, participant: participantState, module } = this.props;
        const isDev = window.location.hostname === 'localhost' || includes('.dev.', window.location.hostname);
        const isDemo = includes('.demo.', window.location.hostname);
        const participant = this.props.params.participant || (participantState ? find(propEq('id', participantState), getParticipants.data.items || []) : {});
        const searchParams = qs.stringify({
            name: `${participant.lastName || ''} ${participant.firstName || ''} ${participant.middleName || ''}`,
            email: participant.email,
            'metadata[participantPublicId]': participant.publicId,
            'metadata[moduleType]': module
        });

        return <Modal
            {...modal}
            title='Добавить встречу'
            onCancel={this.close}
            footer={null}>
            <Row gutter={16} style={{ marginBottom: 15 }}>
                { !this.props.params.participant &&
                    <Col span={12}>
                        <div style={{ marginBottom: 5 }}>Участник</div>
                        <Select
                            value={participantState}
                            loading={getParticipants.meta.pending}
                            onChange={this.onChangeConsultant}
                            style={{ width: '100%' }}
                            placeholder='Выберите участника'
                            showSearch
                        >
                            { (getParticipants.data.items || []).map(participant =>
                                <Select.Option key={participant.id} value={participant.id}>{ participant.lastName } { participant.firstName } { participant.middleName || '' }</Select.Option>
                            )}
                        </Select>
                    </Col>
                }
                { participantState &&
                    <Col span={12}>
                        <div style={{ marginBottom: 5 }}>Сервис</div>
                        <Select value={module} onChange={this.onChangeModule} style={{ width: '100%' }} placeholder='Выберите сервис'>
                            { this.getModules(participant).map(item =>
                                <Select.Option key={item.id} value={item.id}>{ item.name }</Select.Option>
                            )}
                        </Select>
                    </Col>
                }
            </Row>
            { participantState && module &&
                ( modulesLimitTypes[module] && (Math.max(0, getParticipantLimit.data) === 0)
                    ? getParticipantLimit.meta.pending ? <div style={{ textAlign: 'center', padding: 15 }}><Spin /></div> : <div style={{ textAlign: 'center' }}>Внимание, у данного участника все консультации уже истрачены, измените предстоящие консультации или увеличьте лимит у роли</div>
                    : (module === 'consultations' && !pathOr(0, ['amount'], find(propEq('id', 'consultations'), participant.modules || []))) ?
                        <div style={{ textAlign: 'center' }}>Нет доступных консультаций</div> :
                        <iframe
                            key={`${participantState}-${module}`}
                            src={ `https://${!(isDev || isDemo) ? 'cal.meconsultant.ru' : `cal.meconsultant.${isDev ? 'dev' : 'demo'}.experium.net`}/${user.publicId}${module === 'promotion' ? '' : `/${this.getDuration(participant)}min`}?${searchParams}`}
                            title='meetings'
                            frameBorder='0'
                            width="100%"
                            height={710}
                            allowFullScreen />
                )
            }
        </Modal>
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default withState('participant', 'setParticipant', props => props.params.participant ? props.params.participant.id : null)(
    withState('module', 'setModule', () => null)(
        asyncConnect(({ participant }) => ({
            getParticipants: getParticipants
                .withPayload(() => ({
                    limit: 0,
                    q: {
                        depersonalized: false
                    }
                }))
                .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
            getParticipantLimit: getParticipantLimit
                .withPayload(({ participant, module }) => participant && modulesLimitTypes[module] ? ({ id: participant, module: modulesLimitTypes[module] }) : null)
                .withOptions({ dispatchOnMount: !!participant, dispatchOnUpdate: true, resetOnUnmount: true })
        }), stateToProps)(AddMeetingModal)
    )
);
